import { createStore } from 'vuex';

export default createStore({
  state: {
    // Define el estado global aquí
    message: 'Hola, Vuex!',
  },
  mutations: {
    // Define las mutaciones para modificar el estado
    setMessage(state, payload) {
      state.message = payload;
    },
  },
  actions: {
    // Define las acciones que pueden ser asincrónicas y llamar mutaciones
    updateMessage({ commit }, newMessage) {
      commit('setMessage', newMessage);
    },
  },
  getters: {
    // Define los getters para obtener el estado
    getMessage(state) {
      return state.message;
    },
  },
});