<template>
  <div>
    <h1>Asesoría Legal</h1>
    <p>Información sobre nuestra asesoría legal.</p>
  </div>
</template>

<script>
export default {
  name: "AsesoriaLegal",
};
</script>

<style scoped>

</style>