<template>
  <section class="servicios">
    <div class="container">
      <div class="row justify-content-between">
        <CardComponent
          v-for="(novedad, index) in filteredAndSortedNovedades"
          :key="index"
          :imageSrc="getImage(novedad.image)"
          :iconSrc="getImage(novedad.icon)"
          :title="novedad.title"
          :description="novedad.content"
          :linkHref="novedad.link"
          :pdfFile="novedad.file"
          :buttonColor="novedad.color"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import CardComponent from "./CardComponent.vue";

export default defineComponent({
  name: "ServicesComponent",
  components: {
    CardComponent,
  },
  props: {
    novedades: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredAndSortedNovedades() {
      return this.novedades
        .filter((novedad) => novedad.status === 2)
        .sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    getImage(imageName) {
      if (!imageName) return "";

      if (imageName.startsWith('http://') || imageName.startsWith('https://')) {
        return imageName;
      }

      try {
        return require(`@/assets/images/${imageName}`);
      } catch (e) {
        console.error("Image not found:", imageName);
        return "";
      }
    },
  },
});
</script>

<style scoped>
.servicios {
  background: url(@/assets/images/bg_servicios.png) no-repeat;
  background-size: cover;
  padding: 50px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
}

.row > * {
  flex: 1;
  min-width: 250px; /* Ancho mínimo para cada tarjeta */
}

@media (max-width: 768px) {
  .row {
    flex-wrap: wrap;
  }

  .row > * {
    flex: 1 1 100%;
    max-width: 100%;
    min-width: auto; /* Restablecer el ancho mínimo para dispositivos móviles */
  }
}
</style>
