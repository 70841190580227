<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="toggleNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="navbar">
      <ul class="navbar-nav mr-auto">
        <NavbarLink
          v-for="link in fixedLinks"
          :key="link.name"
          :name="link.name"
          :to="link.to"
          :active-page="activePage"
          @link-clicked="handleLinkClick"
        />
        <NavbarDropdown
          v-for="dropdown in dropdowns"
          :key="dropdown"
          :name="dropdown"
          :items="getItemsByDropdown(dropdown)"
          :active-page="activePage"
          :open-dropdown="openDropdown"
          @update-active-page="handleLinkClick"
          @dropdown-toggled="handleDropdownToggle"
          @dropdown-item-clicked="closeMenu"
        />
      </ul>
    </div>
  </nav>
</template>

<script>
import NavbarLink from "./NavbarLink.vue";
import NavbarDropdown from "./NavbarDropdown.vue";
import { navbarData } from "@/services/api/navbar.js";

export default {
  name: "NavbarComponent",
  components: {
    NavbarLink,
    NavbarDropdown,
  },
  props: {
    activePage: String,
  },
  data() {
    return {
      isNavbarOpen: false,
      fixedLinks: [
        { name: "Sobre Nosotros", to: "/sobre-nosotros" },
        { name: "Contacto", to: "/contacto" },
      ],
      dropdowns: ["Divisiones", "Servicios"],
      allItems: navbarData.items,
      openDropdown: null,
    };
  },
  methods: {
    getItemsByDropdown(dropdown) {
      return this.allItems.filter(item => item.dropdown === dropdown);
    },
    handleLinkClick(page) {
      this.$emit("update-active-page", page);
      this.closeMenu();
    },
    handleDropdownToggle(name) {
      if (this.openDropdown === name) {
        this.openDropdown = null;
      } else {
        this.openDropdown = name;
      }
    },
    closeMenu() {
      this.isNavbarOpen = false;
      this.openDropdown = null;
      const navbarCollapse = document.getElementById("navbarSupportedContent");
      if (navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
      const navbarCollapse = document.getElementById("navbarSupportedContent");
      if (navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      } else {
        navbarCollapse.classList.add("show");
      }
    },
    handleClickOutside(event) {
      const navbar = this.$refs.navbar;
      if (navbar && !navbar.contains(event.target) && this.isNavbarOpen) {
        this.closeMenu();
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.navbar {
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 0px;
}

.nav-item {
  padding: 0 15px;
  background-color: #f4f4f4;
  margin-right: 3px;
  font-weight: 500;
  font-size: 16px;
}

.nav-item.active {
   color: #ffffff !important;
  background: #001f5b;
}

.nav-item.active a.nav-link {
  color: #ffffff !important;
}

.nav-link {
  color: #02225f !important;
}

.nav-link:hover,
.nav-link:focus {
  background: #001f5b;
  color: #ffffff !important;
}

.nav-item:hover {
  background: #001f5b;
  color: #ffffff !important;
}

.nav-item:hover a.nav-link {
  background: #001f5b;
  color: #ffffff !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #f8f9f9;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
}

.dropdown-item {
  border-bottom: solid 1px #dfe5e7;
  padding: 5px 15px;
  text-align: center;
  color: #001f5b !important;
}

.dropdown-item:hover {
  color: #fff !important;
  background: #06205c;
}

@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    text-align: center;
  }

  .navbar-toggler {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1050;
  }

  .navbar-collapse {
    background-color: #f8f9f9;
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1040;
    width: 80%;
  }

  .nav-item {
    margin: 5px 0;
    padding: 10px 0;
  }

  .dropdown-menu {
    position: static;
    float: none;
    box-shadow: none;
    padding: 0;
  }
}
</style>
