<template>
  <div class="col-12 col-md-4">
    <div
      class="box-servicio"
      :style="{ 
        backgroundColor: isHovered ? buttonColor : '#fff',
        maxWidth: '400px', 
        width: '100%'
      }"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div class="img-container">
        <img :src="imageSrc" alt="" class="img-servicio" />
      </div>
      <img :src="iconSrc" alt="" class="icono" />
      <div class="titulo-y-boton" :class="{ hovered: isHovered }">
        <h3 :style="{ color: buttonColor }">{{ title }}</h3>
        <p>{{ description }}</p>
        <a
          v-if="linkHref"
          :href="linkHref"
          target="_blank"
          :style="{
            backgroundColor: buttonColor,
            color: '#fff',
            borderColor: isHovered ? '#fff' : buttonColor,
            border: '2px solid',
          }"
        >
          {{ linkText }}
        </a>
        <a
          v-else-if="pdfFile"
          :href="pdfFile"
          target="_blank"
          :style="{
            backgroundColor: buttonColor,
            color: '#fff',
            borderColor: isHovered ? '#fff' : buttonColor,
            border: '2px solid',
          }"
        >
          {{ linkText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    iconSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    linkHref: {
      type: String,
      default: null,
    },
    pdfFile: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: "Ingresar",
    },
    buttonColor: {
      type: String,
      default: "#000000",
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
};
</script>

<style scoped>
.box-servicio {
  cursor: pointer;
  position: relative;
  margin: 50px 0;
  background: #fff;
  text-align: center;
  padding: 0 0 20px 0;
  min-height: 470px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.box-servicio:hover .img-servicio {
  transform: scale(1.05);
}

.box-servicio:hover .icono {
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.img-container {
  overflow: hidden;
  height: 154px;
  position: relative;
}

.box-servicio .img-servicio {
  width: 100%;
  margin: 0 0 15px 0;
}

.img-servicio {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.icono {
  width: 90px;
  position: relative;
  margin: -13px auto 0 auto;
  transform: translateY(-50%);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.titulo-y-boton {
  margin-top: -22px;
  text-align: center;
  transition: color 0.3s ease-in-out;
}

.titulo-y-boton.hovered h3,
.titulo-y-boton.hovered p {
  color: #fff !important;
}

.titulo-y-boton h3 {
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  width: 90%;
  margin: 10px auto 15px auto;
  display: block;
  font-size: 1rem;
  min-height: 45px;
  transition: color 0.3s ease-in-out;
  color: #2cbad2;
}

.titulo-y-boton p {
  color: #898989;
  font-weight: 300;
  width: 90%;
  margin: 0 auto;
  display: block;
  font-size: 15px;
  transition: color 0.3s ease-in-out;
}

.titulo-y-boton a {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding: 8px 20px;
  margin: 40px auto;
  width: 50%;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
</style>
