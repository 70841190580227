<template>
  <section class="aladda">
    <a :href="aladdaUrl" target="_blank">
      <div class="logo-aladda"></div>
    </a>
  </section>
</template>

<script>
import { ALADDA_URL } from "@/services/api/aladda.js";

export default {
  name: "AladdaComponent",
  data() {
    return {
      aladdaUrl: ALADDA_URL,
    };
  },
};
</script>

<style scoped>
.aladda {
  text-align: center;
  padding: 25px 0;
  background-color: #f7f7f7;
}

.logo-aladda {
  display: inline-block;
  background: url(@/assets/images/logo_aladda.png) no-repeat;
  width: 250px;
  height: 68px;
}
</style>