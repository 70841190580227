<template>
  <li>
    <a
      v-if="external"
      class="dropdown-item"
      :href="to"
      target="_blank"
      @click="handleClick"
    >
      {{ name }}
    </a>
    <router-link
      v-else
      class="dropdown-item"
      :to="to"
      @click="handleClick"
    >
      {{ name }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: "DropdownMenuItem",
  props: {
    name: String,
    to: String,
    external: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('item-clicked');
    },
  },
};
</script>

<style scoped>
.dropdown-item {
  border-bottom: solid 1px #dfe5e7;
  padding: 5px 15px;
  text-align: center;
  color: #001f5b !important;
}

.dropdown-item:hover {
  color: #fff !important;
  background: #06205c;
}
</style>