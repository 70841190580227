<template>
  <div>
    <h1>Asesoría Impositiva</h1>
    <p>Información sobre nuestra asesoría impositiva.</p>
  </div>
</template>

<script>
export default {
  name: "AsesoriaImpositiva",
};
</script>

<style scoped>

</style>