<template>
  <div>
    <div class="container first-section">
      <div
        v-for="section in singleColumnSections"
        :key="section.id"
        class="single-column"
        :class="{ 'content-text': section.content && !section.image }"
      >
        <div v-if="section.content && !section.image" v-html="section.content"></div>
        <div v-else-if="section.image && !section.content" class="background-image" :style="{ backgroundImage: `url(${section.image})` }"></div>
      </div>
    </div>

    <div class="container second-section">
      <div class="row">
        <div v-for="section in twoColumnSections" :key="section.id" class="col-md-6">
          <div v-if="section.content && !section.image" v-html="section.content"></div>
          <div v-else-if="section.image && !section.content" class="background-image" :style="{ backgroundImage: `url(${section.image})` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "BlockComponent",
  props: {
    blocks: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const singleColumnSections = computed(() =>
      props.blocks[0].sections
        .filter((section) => section.type === 1)
        .sort((a, b) => a.order - b.order)
    );

    const twoColumnSections = computed(() =>
      props.blocks[0].sections
        .filter((section) => section.type === 2)
        .sort((a, b) => a.order - b.order)
    );

    return {
      singleColumnSections,
      twoColumnSections,
    };
  },
};
</script>

<style scoped>
.first-section {
  background: #ffffff;
  padding: 75px 0;
  text-align: center;
}

.second-section {
  background: #f9f9f9;
  padding: 50px 0;
}

.single-column {
  margin-bottom: 20px;
}

.background-image {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 300px;
}

.content-text {
  color: #757575;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  width: 80%;
}
</style>
