<template>
  <div class="item-slick">
    <div class="box-imagen">
      <div class="date">
        <div class="month">{{ date.split(" ")[0] }}</div>
        <div class="day">{{ date.split(" ")[1] }}</div>
      </div>
      <a :href="link">
        <img :src="getImage(imgSrc)" alt="Novedad Image" />
      </a>
    </div>
    <div class="box-novedad">
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
      <a :href="link">VER MÁS</a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getImage } from '@/utils/mediaUtils.js';

export default defineComponent({
  name: "NovedadCard",
  props: {
    date: String,
    imgSrc: String,
    link: String,
    title: String,
    description: String,
  },
  methods: {
    getImage,
  },
});
</script>

<style scoped>
.item-slick {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.box-imagen {
  position: relative;
}

.date {
  padding: 5px 13px;
  background-color: #0177bd;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  top: 18px;
  left: 20px;
  border-radius: 5px;
  text-align: center;
}

.box-imagen img {
  width: 100%;
  height: auto;
  display: block;
}

.box-novedad {
  background: #fff;
  padding: 40px;
  display: inline-block;
  min-height: 300px;
}

.box-novedad h3 {
  color: #06205c;
  font-weight: 400;
  font-size: 1.2rem;
  min-height: 70px;
}

.box-novedad p {
  color: #898989;
  text-align: left;
  margin: 30px 0;
  min-height: 240px;
}

.box-novedad a {
  color: #06205c;
  font-weight: 400;
  float: right;
  text-decoration: none;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .box-novedad {
    padding: 20px;
    min-height: 250px;
  }

  .box-novedad h3 {
    font-size: 1rem;
    min-height: 60px;
  }

  .box-novedad p {
    margin: 20px 0;
    min-height: 180px;
  }
}

@media (max-width: 480px) {
  .box-novedad {
    padding: 15px;
  }

  .box-novedad h3 {
    font-size: 0.9rem;
  }

  .box-novedad p {
    margin: 15px 0;
  }
}
</style>