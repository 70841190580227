<template>
  <li class="nav-item dropdown" :class="{ active: isActive }">
    <a
      class="nav-link dropdown-toggle"
      href="#"
      role="button"
      :class="{ show: isDropdownOpen }"
      aria-expanded="isDropdownOpen"
      @click.prevent="toggleDropdown"
    >
      {{ name }}
    </a>
    <ul class="dropdown-menu" :class="{ show: isDropdownOpen }">
      <DropdownMenuItem
        v-for="item in items"
        :key="item.name"
        :name="item.name"
        :to="item.to"
        :external="item.external"
        :active-page="activePage"
        @item-clicked="handleItemClick"
      />
    </ul>
  </li>
</template>

<script>
import DropdownMenuItem from "./DropdownMenuItem.vue";

export default {
  name: "NavbarDropdown",
  components: {
    DropdownMenuItem,
  },
  props: {
    name: String,
    items: Array,
    activePage: String,
    openDropdown: String,
  },
  computed: {
    isDropdownOpen() {
      return this.openDropdown === this.name;
    },
    isActive() {
      return this.isDropdownOpen || this.items.some(item => item.to === this.activePage);
    },
  },
  methods: {
    toggleDropdown() {
      this.$emit("dropdown-toggled", this.name);
    },
    handleItemClick(itemName) {
      this.$emit("dropdown-item-clicked");
      this.$emit("update-active-page", itemName); 
    },
  },
};
</script>

<style scoped>

.nav-item.dropdown .nav-link {
  cursor: pointer; 
  color: #02225f !important;
}

.nav-item.dropdown.active .nav-link,
.nav-item.dropdown .nav-link:hover,
.nav-item.dropdown .nav-link:focus {
  background: #001f5b; 
  color: #ffffff !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: #f8f9f9;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
}

.dropdown-menu.show {
  display: block; 
}

.dropdown-item {
  color: #001f5b !important;
}

.dropdown-item:hover {
  background: #06205c;
  color: #ffffff !important;
}
</style>
