<template>
  <div class="loading-indicator">
    <div class="spinner"></div>
    <p>Cargando...</p>
  </div>
</template>

<script setup>

</script>

<style scoped>
.loading-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #007bff;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 123, 255, 0.2);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-indicator p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #007bff;
}
</style>
