<template>
  <header class="header">
    <div class="container">
      <LogoComponent @logo-clicked="resetActivePage" />
      <NavbarComponent
        :active-page="activePage"
        @update-active-page="setActivePage"
      />
    </div>
  </header>
</template>

<script>
import LogoComponent from "@/components/header/LogoComponent.vue";
import NavbarComponent from "@/components/header/navbar/NavbarComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    LogoComponent,
    NavbarComponent,
  },
  data() {
    return {
      activePage: "", 
    };
  },
  methods: {
    setActivePage(page) {
      this.activePage = page;
    },
    resetActivePage() {
      this.activePage = ""; 
    },
  },
};
</script>

<style scoped>
.header {
  color: white;
  padding: 0px 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .header {
    padding: 10px; 
  }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
</style>
