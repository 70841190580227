<template>
  <div>
    <h1>Socios</h1>
    <p>Información sobre nuestros socios.</p>
  </div>
</template>

<script>
export default {
  name: "SociosView",
};
</script>

<style scoped>

</style>