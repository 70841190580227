<template>
  <div class="logo-container" @click="handleLogoClick">
    <router-link to="/">
      <img :src="logoSrc" class="logo" alt="ACARA Logo" />
    </router-link>
  </div>
</template>

<script>
import { logoImage } from "@/services/api/logo.js";

export default {
  name: "LogoComponent",
  data() {
    return {
      logoSrc: require(`@/assets/images/${logoImage}`),
    };
  },
  methods: {
    handleLogoClick() {
      this.$emit("logo-clicked");
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.logo {
  height: auto;
  max-width: 125px;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .logo {
    max-width: 100px;
  }
}
</style>