<template>
  <div v-if="loading" class="loading-indicator">
    <LoadingIndicator />
  </div>
  <div v-else>
    <!-- Pasar las slides obtenidas a CarouselComponent -->
    <CarouselComponent :slides="allViews.sliders" />
    <!-- Pasar las novedades obtenidas a ServicesComponent -->
    <ServicesComponent v-if="allViews.cards.length > 0" :novedades="allViews.cards" />
    <NovedadComponent />
    <SucursalComponent />
    <AladdaComponent />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useImageLoader } from '@/composables/useImageLoader';
import { fetchViewByUrl } from "@/services/api/view.js";

import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
import ServicesComponent from "@/components/servicios/Servicio.vue";
import NovedadComponent from "@/components/novedades/Novedad.vue";
import SucursalComponent from "@/components/sucursal/Sucursal.vue";
import AladdaComponent from "@/components/aladda/Aladda.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";

const { loading, allViews, loadImages } = useImageLoader();

onMounted(async () => {
  await loadImages(fetchViewByUrl);
});
</script>

<style scoped>
.servicios {
  background: url(@/assets/images/bg_servicios.png) no-repeat;
  background-size: cover;
  padding: 50px 0;
}
</style>
