export function getImage(imageName) {
  try {
    return require(`@/assets/images/${imageName}`);
  } catch (e) {
    console.error('Image not found:', imageName);
    return '';
  }
}

export function getMediaPath(fileName) {
  try {
    return require(`@/assets/videos/${fileName}`);
  } catch (e) {
    console.error('Media not found:', fileName);
    return '';
  }
}


export function getImageApi(imageName) {
  try {
    return imageName;
  } catch (e) {
    console.error('Image not found:', imageName);
    return '';
  }
}

export function getMediaPathApi(fileName) {
  try {
    return fileName;
  } catch (e) {
    console.error('Media not found:', fileName);
    return '';
  }
}