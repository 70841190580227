const novedadesData = [
    {
      date: "May 01",
      imgSrc: "novedad-marzo-31.jpg",
      link: "novedades-patentamientos-abril-autos-2024.php",
      title: "AUTOS: Se patentaron en abril 32.710 vehículos",
      description: "La Asociación de Concesionarios de Automotores de la República Argentina (ACARA) informa que el número de vehículos patentados durante abril de 2024 ascendió a 32.710 unidades, lo que representa una baja del 5,9% interanual, ya que en abril de 2023 se habían registrado 34.766 unidades. Si la comparación es con marzo de este año...",
    },
    {
      date: "May 01",
      imgSrc: "novedad-marzo-21.jpg",
      link: "novedades-patentamientos-abril-motos-2024.php",
      title: "MOTOS: Se patentaron en abril 38.214 motovehículos",
      description: "La División Motovehículos de la Asociación de Concesionarios de Automotores de la República Argentina (ACARA) informa que el número de unidades patentadas durante marzo de 2024 fue de 27.449 motovehículos, esto es una baja interanual del 43,3% ya que en marzo de 2023 se habían registrado 48.411 unidades...",
    },
    {
      date: "May 01",
      imgSrc: "novedad-febrero-21.jpg",
      link: "novedades-patentamientos-abril-2024-agricola.php",
      title: "MAQUINARIA AGRÍCOLA: En abril se patentaron 504 maquinarias agrícolas",
      description: "La División de Maquinaria Agrícola la Asociación de Concesionarios de Automotores de la República Argentina (ACARA) informa que los patentamientos de cosechadoras, tractores y pulverizadoras de abril de 2024 alcanzaron las 504 unidades, una baja del 53% interanual ya que...",
    },
  ];
  
  export default novedadesData;  