<template>
  <footer>
    <div class="container">
      <div class="row"></div>
    </div>
    <div class="terminos">
      <div class="container">
        <div class="row">
          <div class="col-8 col-md-6 text-left mb-3 mb-md-0">
            <p>{{ getAddress() }}</p>
            <p>{{ currentYear }} ACARA - Todos los derechos reservados.</p>
          </div>
          <div class="col-4 col-md-6 text-md-right social-icons text-right">
            <a v-for="(link, index) in getSocialLinks()" :key="index" :href="link.url" target="_blank">
              <i :class="link.iconClass" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-md-right text-right">
            <a
              v-for="(appLink, index) in getAppLinks()"
              :key="index"
              :href="appLink.url"
              target="_blank"
              :title="appLink.title"
              class="app-link"
            >
              <img
                :src="getImage(appLink.image)"
                class="app-image"
                :alt="appLink.alt"
                width="120"
                height="35"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getImage } from '@/utils/mediaUtils.js';

export default {
  name: "TerminosComponent",
  props: {
    footerData: {
      type: Array,
      required: true,
    },
    currentYear: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getImage,
    getAddress() {
      return this.footerData.find(item => item.address)?.address || '';
    },
    getSocialLinks() {
      return [
        { url: this.footerData.find(item => item.instagram)?.instagram, iconClass: 'fab fa-instagram' },
        { url: this.footerData.find(item => item.facebook)?.facebook, iconClass: 'fab fa-facebook-f' },
        { url: this.footerData.find(item => item.x)?.x, iconClass: 'fab fa-twitter' },
      ];
    },
    getAppLinks() {
      return [
        {
          url: this.footerData.find(item => item.google_play)?.google_play,
          image: 'google-play.svg',
          alt: 'Descargar nuestra app en Google Play',
          title: 'Descargar nuestra app en Google Play',
        },
        {
          url: this.footerData.find(item => item.app_store)?.app_store,
          image: 'app-store.svg',
          alt: 'Descargar nuestra app en el App Store',
          title: 'Descargar nuestra app en el App Store',
        }
      ];
    }
  },
};
</script>

<style scoped>
footer .terminos {
  background: #011f5b;
  color: #6db2d9;
  padding: 30px 0 20px 0;
}

footer .terminos p {
  font-size: 12px;
}

footer .social-icons i {
  margin-right: 20px;
}

footer .social-icons a {
  margin-right: 20px;
  color: #6db2d9;
}

footer .social-icons a:hover {
  color: #ffffff;
}

footer .app-link {
  margin-right: 2rem;
  display: inline-block;
}

footer .app-link:hover {
  opacity: 0.8;
  cursor: pointer;
}

footer .app-link:last-child {
  margin-right: 0;
}

footer .app-image {
  border-radius: 0.25rem;
  width: 7.5rem;
  height: 2.1875rem;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
</style>
