<template>
  <section class="comision-directiva" style="margin-top: 35px">
    <div class="container">
      <div class="row">
        <div
          v-for="(link, index) in links"
          :key="index"
          class="col-12 col-md-4 text-center"
        >
          <a
            v-if="!isLoggedIn && link.modalHref !== null"
            href="#"
            @click.prevent="openLoginModal"
          >
            <img :src="getImage(link.imgSrc)" />
            <h5>{{ link.text }}</h5>
          </a>
          <a v-else-if="link.href !== null" :href="link.href">
            <img :src="getImage(link.imgSrc)" />
            <h5>{{ link.text }}</h5>
          </a>
          <span v-else>
            <img :src="getImage(link.imgSrc)" />
            <h5>{{ link.text }}</h5>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { comisionDirectivaData } from "@/services/api/comisionDirectiva.js";
import { getImage } from "@/utils/mediaUtils.js";

export default {
  name: "ComisionDirectiva",
  setup() {
    const isLoggedIn = ref(false);
    const links = ref(comisionDirectivaData.links);

    const openLoginModal = () => {
      console.log("Abrir modal de inicio de sesión");
    };

    return {
      isLoggedIn,
      links,
      getImage,
      openLoginModal,
    };
  },
};
</script>

<style scoped>
a {
  background-color: transparent;
  text-decoration: none;
}

h5 {
  color: #00a0e1;
  text-transform: uppercase;
  margin-top: 15px;
}

.comision-directiva{
  margin-bottom: 50px;
}
</style>
