<template>
  <div>
    <h1>Capacitación</h1>
    <p>Información sobre nuestra capacitación.</p>
  </div>
</template>

<script>
export default {
  name: "CapacitacionView",
};
</script>

<style scoped>

</style>