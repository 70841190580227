export const sucursalSlides = [
    {
      id: 1,
      images: [
        { src: "img-regional-01-buenos-aires.jpg", alt: "Buenos Aires" },
        { src: "img-regional-02-bahia-blanca.jpg", alt: "Bahía Blanca" },
        { src: "img-regional-03-capital-federal.jpg", alt: "Capital Federal" },
        { src: "img-regional-04-catamarca.jpg", alt: "Catamarca" },
      ],
    },
    {
      id: 2,
      images: [
        { src: "img-regional-04-corrientes.jpg", alt: "Corrientes" },
        { src: "img-regional-05-jujuy.jpg", alt: "Jujuy" },
        { src: "img-regional-07-la-pampa.jpg", alt: "La Pampa" },
        { src: "img-regional-07-la-rioja.jpg", alt: "La Rioja" },
      ],
    },
  ];
  