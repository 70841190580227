export const footer = [
  { address: "Av Córdoba 3371, CABA" },
  { instagram: "https://www.instagram.com/acaraoficial/?hl=es-la" },
  { facebook: "https://www.facebook.com/Acara-Oficial-100506451482577/" },
  { x: "https://twitter.com/acaraoficial" },
  { google_play: "https://play.google.com/store/apps/details?id=com.acara.app" },
  { app_store: "https://apps.apple.com/us/app/acara-gu%C3%ADa-oficial-de-precios/id1567450301" },
  { phone: '(011) 5236 6500' },
  { email: 'socios@acara.org.ar' },
  { status: 2 },
];