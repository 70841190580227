<template>
  <li class="nav-item" :class="{ active: activePage === name }">
    <router-link class="nav-link" :to="to" @click="handleClick">
      {{ name }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: "NavbarLink",
  props: {
    name: String,
    to: String,
    activePage: String,
  },
  methods: {
    handleClick() {
      this.$emit('link-clicked', this.name);
    },
  },
};
</script>

<style scoped>
.nav-item {
  padding: 0 15px;
  background-color: #f4f4f4;
  margin-right: 3px;
  font-weight: 500;
  font-size: 14px;
}

.nav-item.active {
  background: #001f5b;
}

.nav-item.active a.nav-link {
  color: #ffffff !important;
}

.nav-link {
  color: #02225f !important;
}

.nav-link:hover,
.nav-link:focus {
  background: #001f5b;
  color: #fff!important;
}
</style>