export const comisionDirectivaData = {
    links: [
      {
        href: "/files/socios/PROTOCOLO_ACARA.pdf",
        imgSrc: "icon_protocolo.png",
        text: "Protocolo ACARA",
        modalHref: null, 
      },
      {
        href: "https://www.google.com",
        imgSrc: "icon_comision_directiva.png",
        text: "Comisión Directiva",
      },
      {
        href: "/files/socios/ESTATUTO_ACARA_VIGENTE.pdf",
        imgSrc: "icon_estatuto.png",
        text: "Estatuto",
        modalHref: null, 
      },
    ],
  };
  