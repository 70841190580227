<template>
  <div>
    <h1>Contacto</h1>
    <p>Información de contacto.</p>
  </div>
</template>

<script>
export default {
  name: "ContactoView",
};
</script>

<style scoped>

</style>