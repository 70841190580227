<template>
  <div v-if="isVisible">
    <ContactoComponent :footerData="footerData" />
    <TerminosComponent
      :footerData="footerData"
      :currentYear="currentYear"
    />
  </div>
</template>

<script>
import ContactoComponent from "@/components/footer/ContactoComponent.vue";
import TerminosComponent from "@/components/footer/TerminosComponent.vue";
import { footer } from '@/services/api/footer';

export default {
  name: "FooterComponent",
  components: {
    ContactoComponent,
    TerminosComponent,
  },
  data() {
    return {
      footerData: footer,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isVisible() {
      const statusItem = this.footerData.find(item => item.status !== undefined);
      return statusItem ? statusItem.status === 2 : false;
    },
  },
};
</script>