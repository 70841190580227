import axios from '@/plugins/axios';

export async function fetchViewByUrl() {
    try {
        const currentUrl = window.location.pathname;
        
        const identifier = currentUrl === '/' ? 'index' : encodeURIComponent(currentUrl.substring(1));

        const response = await axios.get(`/views/${identifier}`);
        
        return response.data;
    } catch (error) {
        console.error("Error al obtener la vista:", error);
        throw error;
    }
}
