<template>
  <div>
    <h1>Asesoría Registral</h1>
    <p>Información sobre nuestra asesoría registral.</p>
  </div>
</template>

<script>
export default {
  name: "AsesoriaRegistral",
};
</script>

<style scoped>

</style>